//
// user.js
// Use this to write your custom JS
//

import { Collapse } from 'bootstrap';

const toggles = document.querySelectorAll('[data-collapse-show]');

toggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
        const target = toggle.getAttribute('data-target');
        const targetEl = document.querySelector(target);

        const instance = Collapse.getOrCreateInstance(targetEl, { toggle: true });
        instance.show();
    });
});
